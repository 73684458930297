var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[_c('div',{staticClass:"d-flex align-center"},[_c('v-row',{staticClass:"justify-space-between ma-2"},[_c('div',[_c('h3',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.title))])]),_c('div',[_c('div',{staticClass:"row d-flex align-center"},[_c('InformationDDC',{attrs:{"redPercent":50}}),_c('v-btn',{attrs:{"icon":"","color":"green","large":""},on:{"click":_vm.exportData}},[_c('v-icon',[_vm._v("mdi-microsoft-excel")])],1)],1)])])],1),_c('v-row',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.data,"loading":_vm.isLoading,"disable-pagination":"","disable-sort":"","fixed-header":"","hide-default-footer":"","dense":"","height":_vm.maxtableHeight},scopedSlots:_vm._u([_vm._l((_vm.headers.filter((header) =>
          header.hasOwnProperty('formatter')
        )),function(header){return {key:`item.${header.value}`,fn:function({ header, value }){return [_vm._v(" "+_vm._s(header.formatter(value))+" ")]}}}),{key:"item.fC_FVIII_Per_Capita_Perc_Diff",fn:function({ item }){return [_c('span',{class:_vm.highlight(item.fC_FVIII_Per_Capita_Perc_Diff, 50)
              ? 'primary--text'
              : ''},[_vm._v(" "+_vm._s(item.fC_FVIII_Per_Capita_Perc_Diff !== null ? _vm.formatValue(item.fC_FVIII_Per_Capita_Perc_Diff) : "-")+" ")])]}},{key:"item.previous_FC_FVIII_Per_Capita",fn:function({ item }){return [_c('span',{class:_vm.highlight(item.previous_FC_FVIII_Per_Capita, 50)
              ? 'primary--text'
              : ''},[_vm._v(" "+_vm._s(item.previous_FC_FVIII_Per_Capita !== null ? _vm.formatValue(item.previous_FC_FVIII_Per_Capita, 6) : "-")+" ")])]}},{key:"item.current_FC_FVIII_Per_Capita",fn:function({ item }){return [_c('span',{class:_vm.highlight(item.current_FC_FVIII_Per_Capita, 50)
              ? 'primary--text'
              : ''},[_vm._v(" "+_vm._s(item.current_FC_FVIII_Per_Capita !== null ? _vm.formatValue(item.current_FC_FVIII_Per_Capita, 6) : "-")+" ")])]}},{key:"item.fC_FVIII_Per_Capita_Difference",fn:function({ item }){return [_c('span',{class:_vm.highlight(item.fC_FVIII_Per_Capita_Difference, 50)
              ? _vm.formatValue(item.current_FC_FVIII_Per_Capita, 6)
              : ''},[_vm._v(" "+_vm._s(item.fC_FVIII_Per_Capita_Difference !== null ? _vm.formatValue(item.fC_FVIII_Per_Capita_Difference, 6) : "-")+" ")])]}},{key:"item.current_FC_FIX_Per_Capita",fn:function({ item }){return [_c('span',{class:_vm.highlight(item.current_FC_FIX_Per_Capita, 50)
              ? _vm.formatValue(item.current_FC_FVIII_Per_Capita, 6)
              : ''},[_vm._v(" "+_vm._s(item.current_FC_FIX_Per_Capita !== null ? _vm.formatValue(item.current_FC_FIX_Per_Capita, 6) : "-")+" ")])]}},{key:"item.fC_FIX_Per_Capita_Difference",fn:function({ item }){return [_c('span',{class:_vm.highlight(item.fC_FIX_Per_Capita_Difference, 50)
              ? _vm.formatValue(item.current_FC_FIX_Per_Capita, 6)
              : ''},[_vm._v(" "+_vm._s(item.fC_FIX_Per_Capita_Difference !== null ? _vm.formatValue(item.fC_FIX_Per_Capita_Difference, 6) : "-")+" ")])]}},{key:"item.previous_FC_FIX_Per_Capita",fn:function({ item }){return [_c('span',{class:_vm.highlight(item.previous_FC_FIX_Per_Capita, 50)
              ? _vm.formatValue(item.current_FC_FIX_Per_Capita, 6)
              : ''},[_vm._v(" "+_vm._s(item.previous_FC_FIX_Per_Capita !== null ? _vm.formatValue(item.previous_FC_FIX_Per_Capita, 6) : "-")+" ")])]}},{key:"item.fC_FIX_Per_Capita_Perc_Diff",fn:function({ item }){return [_c('span',{class:_vm.highlight(item.fC_FIX_Per_Capita_Perc_Diff, 50)
              ? 'primary--text'
              : ''},[_vm._v(" "+_vm._s(item.fC_FIX_Per_Capita_Perc_Diff !== null ? _vm.formatValue(item.fC_FIX_Per_Capita_Perc_Diff) : "-")+" ")])]}}],null,true)})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }